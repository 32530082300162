import React, {useEffect, useState} from 'react'
import Layout from '../components/login/Layout'
import Hero from '../components/login/Hero'
import Card from '../components/login/Card'
import { AuthAPI } from '../api/Auth'


const Home = () => {
  const [links, setLinks] = useState([{ google: null, linkedin: null, facebook: null}]);

  useEffect(() => {
    AuthAPI.get(`api/auth/redirects`)
      .then((response) => setLinks(response.data))
      
  },[])

  return (
    <Layout>
      <Hero />
      <Card links={links}/>

    </Layout>
  )
}

export default Home