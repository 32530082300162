import React from 'react'

const Layout = ({children}) => {
  return (
    <React.Fragment>
      <div className="relative h-screen items-center justify-center sm:flex lg:gap-x-20 md:py-8">
        <div className="absolute -z-10 h-screen w-full bg-[url('https://i.imgur.com/7ymGcc0.png')] bg-cover bg-center bg-no-repeat brightness-50"></div>
        {children}
        <div className="hidden md:block absolute bottom-0 left-8 h-16 w-22 text-white text-lg">
          <p>
            Pinsel ID <span className="font-bold">- v1.0</span> 
          </p>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Layout