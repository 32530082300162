import React from 'react'

const Hero = ({service}) => {
  return (
    <div className="mx-auto max-w-md text-center sm:mx-1 lg:flex-auto sm:text-left py-4">
      <h1 className="text-3xl font-bold tracking-tight text-white md:text-5xl">Pinsel ID</h1>
      <p className="text-lg text-white">{service ? 'Iniciando con '+service : 'Inicia sesión o crea una cuenta'}</p>
    </div>
  )
}

export default Hero