import axios from "axios";

// Microservicio de Autorización
export const AuthAPI = axios.create({
    baseURL: "https://auth.services.pinsel.app/",
    headers: {
      "Accept": "application/json",
    },
});

// Guardar en cookie el token de auth