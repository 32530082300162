import React, { useEffect } from 'react'
import Hero from '../components/login/Hero'
import Layout from '../components/login/Layout'
import {useParams} from 'react-router-dom'
import CardAuth from '../components/auth/CardAuth'
import { AuthAPI } from '../api/Auth'

const Login = () => {

  const { service } = useParams();
  useEffect(() => {
    AuthAPI.get(`api/auth/${service}/callback/${window.location.search}`)
    .then(res => {
      console.log(res.data)
    })
    .catch(err => console.log(err));
  }, [service])
  return (
    <Layout>
      <Hero service={service} />
      <CardAuth />
      
    </Layout>
  )
}

export default Login