import React from "react";
import {Route, Routes} from 'react-router-dom'

import Home from './pages/Home';
import Login from './pages/Login';
import Error from './pages/Error';


function App() {
  return (
    <div>
      <Routes>
        <Route path='/' element={<Home />}/>
        <Route path='/auth/:service/callback' element={<Login />}/>
        <Route path="*" element={<Error />} />
      </Routes>
    </div>
 
  );
}

export default App;


