import React from 'react'
import {Link} from 'react-router-dom'

const Error = () => {
  return (
    <div className="relative h-screen overflow-hidden bg-gray-800">
     
      <div className="container relative z-10 flex items-center px-6 py-32 mx-auto md:px-12 xl:py-40">
          <div className="relative z-10 flex flex-col items-center w-full font-mono">
              <h1 className="mt-4 text-5xl font-extrabold leading-tight text-center text-white">
                  ¡Nada que ver aqui! <br />
                  <p className="text-8xl mt-10 animate-bounce">
                    404
                  </p>
              </h1>
              <p className="font-extrabold text-white text-2xl mt-10 hover:text-sky-500">
                 <Link to="/">Ir a la pagina de inicio</Link>
              </p>
          </div>
      </div>
  </div>
  )
}

export default Error