import React from 'react'

const Card = ({links}) => {
  //console.log(links)
  let { google, linkedin, facebook} = links

  return (
    <div className="mx-auto max-w-md sm:mx-0 sm:flex-auto p-4 md:p-0">
        <div className="flex w-full max-w-md flex-col rounded-lg bg-white p-8 md:px-12 md:py-10 shadow">
          <div className="mb-2 text-3xl font-bold tracking-tight text-gray-900">Inicio de sesión</div>

         
          <div className="item-center mt-4 flex gap-4">
            <a href={google ? google : '#'} className="flex w-full items-center justify-center rounded-lg bg-white py-2 px-4 text-center text-base font-semibold text-gray-700 transition duration-200 ease-in hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 focus:ring-offset-red-200 border-[3px] border-gray-700 hover:border-red-500 hover:text-gray-100">
              <svg width="20" height="20" fill="currentColor" className="mr-2" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                <path d="M896 786h725q12 67 12 128 0 217-91 387.5t-259.5 266.5-386.5 96q-157 0-299-60.5t-245-163.5-163.5-245-60.5-299 60.5-299 163.5-245 245-163.5 299-60.5q300 0 515 201l-209 201q-123-119-306-119-129 0-238.5 65t-173.5 176.5-64 243.5 64 243.5 173.5 176.5 238.5 65q87 0 160-24t120-60 82-82 51.5-87 22.5-78h-436v-264z"></path>
              </svg>
              Inicia con Google
            </a>
          </div>
          <div className="item-center mt-2 flex gap-4">
            <a href={linkedin ? linkedin : '#'} className="flex w-full items-center justify-center rounded-lg bg-white py-2 px-4 text-center text-base font-semibold text-gray-700 transition duration-200 ease-in hover:bg-sky-500 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 focus:ring-offset-sky-200 border-[3px] border-gray-700 hover:border-sky-500 hover:text-gray-100">
              <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="mr-2 -mt-1" width="18" height="18" viewBox="0 0 24 24"><path d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z"/></svg>
              Inicia con Linkedin
            </a>
          </div>
          <div className="item-center mt-2 flex gap-4">
           <a href={facebook ? facebook : '#'} className="flex w-full items-center justify-center rounded-lg bg-white py-2 px-4 text-center text-base font-semibold text-gray-700 transition duration-200 ease-in hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-blue-200 border-[3px] border-gray-700 hover:border-blue-500 hover:text-gray-100">
              <svg width="20" height="20" fill="currentColor" className="mr-2" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                <path d="M1343 12v264h-157q-86 0-116 36t-30 108v189h293l-39 296h-254v759h-306v-759h-255v-296h255v-218q0-186 104-288.5t277-102.5q147 0 228 12z"></path>
              </svg>
              Inicia con Facebook
            </a>
          </div>
          
          {/* divider */}
          <div className="relative mt-6">
            <div className="absolute inset-0 flex items-center">
              <div className="w-full border-t border-gray-300"></div>
            </div>
            <div className="relative flex justify-center text-sm leading-7">
              <span className="bg-white px-6 text-gray-500"> O </span>
            </div>
          </div>
          {/* Forms Login*/}
           <div className="mt-4">
            <form action="/" autoComplete="off">
              <div className="mb-4 flex flex-col">
                <div className="relative">
                  <span className="text-sm text-gray-400">Correo electronico</span>
                  <input type="text" id="sign-in-email" className="w-full flex-1 appearance-none border border-x-0 border-t-0 bg-white py-1 text-base text-gray-700 placeholder-gray-400 focus:border-transparent focus:border-b-sky-500 focus:outline-none focus:ring-sky-600" placeholder="" />
                </div>
              </div>
              <div className="mb-6 flex flex-col">
                <div className="relative">
                  <span className="text-sm text-gray-400">Contraseña</span>
                  <input type="password" id="sign-in-email" className="w-full flex-1 appearance-none border border-x-0 border-t-0 bg-white py-1 text-base text-gray-700 placeholder-gray-400 focus:border-transparent focus:border-b-sky-500 focus:outline-none focus:ring-sky-600" placeholder="" />
                </div>
              </div>
              <div className="mb-8 -mt-2 flex items-center">
                <div className="ml-auto flex">
                  <a href="/" className="inline-flex text-xs font-thin text-gray-500 hover:text-sky-700 sm:text-sm"> ¿Olvidaste tu contraseña? </a>
                </div>
              </div>
              <div className="flex w-full">
                <button type="submit" className="w-full rounded-lg bg-sky-600 py-2 px-4 text-center text-base font-semibold text-white shadow-md transition duration-200 ease-in hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-700 focus:ring-offset-2 focus:ring-offset-sky-200">
                  Iniciar
                </button>
              </div>
            </form>
          </div>
          
        </div>
    </div>
  )
}

export default Card